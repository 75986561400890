import { Flex, Icon } from '@chakra-ui/react';

import { BsArrowsCollapse } from 'react-icons/bs';
import Divider from '../shared/Divider';
import { Market } from '@/types';
import React from 'react';
import TableRow from '../shared/Table/TableRow';
import Text from '@/components/shared/Text';

interface Props {
  expandedDefaultMarketIds: string[];
  market: Market;
  setExpandedDefaultMarketIds?: React.Dispatch<React.SetStateAction<any[]>>;
}

const ProtectionFamilyFooter: React.FC<Props> = ({
  expandedDefaultMarketIds,
  market,
  setExpandedDefaultMarketIds,
}: Props) => {
  const onClick = () => {
    const withoutThisMarket = expandedDefaultMarketIds.filter((marketId) => marketId != market.id);
    setExpandedDefaultMarketIds(withoutThisMarket);
  };

  return (
    <>
      <Divider />

      <TableRow px={6} py={4} alignItems="center" justifyContent="center" onClick={onClick}>
        <Flex alignItems="center" justifyContent="center">
          <Text fontSize="sm" fontWeight="600" mr={2}>
            Close Options
          </Text>
          <Icon as={BsArrowsCollapse} boxSize="16px" color="gray.500" mt="2px" />
        </Flex>
      </TableRow>
    </>
  );
};
export default ProtectionFamilyFooter;
