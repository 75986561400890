import { Box } from '@chakra-ui/react';
import Divider from '../Divider';
import React from 'react';

const TableEmptySpace: React.FC = () => {
  return (
    <>
      <Divider />
      <Box height={2} background="gray.50" />
      <Divider />
    </>
  );
};
export default TableEmptySpace;
