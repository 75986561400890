import { Account, ButtonType } from '@/types';
import { Box, Flex, Progress, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { formatPercentage, formatUsdAmount } from '@/helpers/formatHelpers';

import Button from './Button';
import NuxBorrowingPowerPopover from '../popovers/NuxBorrowingPowerPopover';
import Text from '@/components/shared/Text';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';
import { chooseBorrowingPowerBarColor } from '@/helpers/borrowingPowerHelpers';
import { isOnFundsPage } from '../modals/manage_modal/BorrowingPowerModalPanel/BorrowingPowerDisplay';
import { trackEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';
import { useViewportScroll } from 'framer-motion';

interface Props {
  account?: Account;
}

const SCROLL_Y_PROGRESS_HIDE_AT = 0.7;

const BorrowLimitPanel: React.FC<Props> = ({ account }: Props) => {
  const router = useRouter();
  const { scrollYProgress } = useViewportScroll();
  const [hidden, setHidden] = useState(false);

  const update = () => {
    const current = scrollYProgress.get();
    const previous = scrollYProgress.getPrevious();

    if (current < SCROLL_Y_PROGRESS_HIDE_AT && current < previous) {
      setHidden(false);
    } else if (current > SCROLL_Y_PROGRESS_HIDE_AT && current > previous) {
      setHidden(true);
    }
  };

  useEffect(() => scrollYProgress.onChange(() => update()));

  const {
    borrowLimitUsdLeft,
    percentBorrowed,
    borrowLimitUsdTotal,
    isInLiquidation,
    isNearLiquidation,
  } = calculateBorrowLimitStats(account);

  const colorScheme = chooseBorrowingPowerBarColor(isInLiquidation, isNearLiquidation);

  const onDepositClick = () => {
    trackEvent('Deposit CTA Clicked', { location: 'borrowing-power-bar' });
    router.push('/funds');
  };

  const { pathname } = router;

  return (
    <Flex
      alignItems="center"
      background="white"
      borderTop="1px"
      borderColor="gray.100"
      bottom={0}
      dir="column"
      justifyContent="center"
      position="fixed"
      transform={hidden ? 'translate(0%, 100%)' : null}
      transition="transform 0.3s"
      transitionTimingFunction="ease-in-out"
      width="100%"
      zIndex="sticky"
    >
      <NuxBorrowingPowerPopover borrowLimitUsdTotal={borrowLimitUsdTotal} />
      <Box minWidth={{ base: '20em', md: '50em', xl: '70em' }} p={6}>
        <Stack direction={{ base: 'column', md: 'row' }} alignItems="center" spacing={{ base: 6, md: 0 }}>
          <Box flex={1}>
            <Flex alignItems="flex-start" justifyContent="space-between">
              <Flex fontSize="lg" alignItems="flex-start">
                <Text fontWeight="600">{formatUsdAmount(borrowLimitUsdTotal, '$0,0.00')} </Text>{' '}
                <Text ml={2}> Borrowing Power</Text>
              </Flex>

              {!isOnFundsPage(pathname) && (
                <Button
                  height="22px"
                  mr="-12px"
                  color="purple.500"
                  size="sm"
                  appButtonType={ButtonType.Ghost}
                  onClick={onDepositClick}
                >
                  Deposit Funds
                </Button>
              )}
            </Flex>

            <Flex mt={2} alignItems="center">
              <Text mr={6} fontSize="sm" fontWeight="600" color="gray.500">
                {formatPercentage(percentBorrowed, '0%')} used
              </Text>
              <Progress
                size="sm"
                borderRadius="100px"
                value={percentBorrowed * 100}
                flex={1}
                colorScheme={colorScheme}
              />
              <Text fontSize="sm" fontWeight="600" color="gray.500" ml={6}>
                {`${formatUsdAmount(borrowLimitUsdLeft, '$0,0.00')} available`}
              </Text>
            </Flex>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
};
export default BorrowLimitPanel;
