import { Box, Flex } from '@chakra-ui/react';
import { Market, Position } from '@/types';

import BalanceText from '../wallet/BalanceText';
import { PLATFORMS } from '@/constants';
import PlatformLogoAndName from '../invest/PlatformLogoAndName';
import React from 'react';
import Text from '@/components/shared/Text';
import TokenLogo from '@/components/shared/TokenLogo';
import { findDefaultPlatformByTrigger } from '@/helpers/findHelpers';

interface Props {
  flex: number;
  market: Market;
  position?: Position;
  showBalance?: boolean;
  showPlatform?: boolean;
  walletAccount?: string;
}

const AssetCell: React.FC<Props> = ({
  flex,
  market,
  position,
  showBalance = false,
  showPlatform,
  walletAccount,
}: Props) => {
  const platform = findDefaultPlatformByTrigger(PLATFORMS, market.trigger);

  const bottomMarginUnderAssetName =
    (position?.borrowBalanceUnderlying > 0 && walletAccount) || showPlatform || showBalance ? 1 : 0;

  return (
    <Flex flex={flex} alignItems="center">
      <TokenLogo boxSize={8} name={market.underlyingName} symbol={market.underlyingSymbol} />
      <Box ml={4} mr={4}>
        <Text fontSize="md" fontWeight="600" mb={bottomMarginUnderAssetName}>
          {market.underlyingName}
        </Text>
        {showPlatform && <PlatformLogoAndName color="gray.500" platform={platform} fontWeight="400" />}

        {showBalance && (
          <BalanceText
            balanceLabel="in wallet"
            fontWeight="400"
            color="gray.500"
            fontSize="sm"
            tokenAddress={market.underlyingAddress}
            tokenDecimals={market.underlyingDecimals}
            tokenSymbol={market.underlyingSymbol}
          />
        )}
      </Box>
    </Flex>
  );
};
export default AssetCell;
