import React, { ReactNode } from 'react';

import { BoxProps } from '@chakra-ui/react';
import Container from './Container';

interface Props extends BoxProps {
  children: ReactNode;
}

const StatContainer: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <Container borderRadius="xl" border="0px" backgroundColor="gray.50" {...props}>
      {children}
    </Container>
  );
};
export default StatContainer;
