import {
  Account,
  EthereumTransaction,
  EthereumTransactionTypes,
  Market,
  PositionTypes,
  ProtectionRowLayout,
} from '@/types';
import { Box, Flex, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { calculatePositionStats, checkIfPositionHasBalance } from '@/helpers/positionHelpers';

import AlternateTriggerInfoCombinedCell from '../funds/AlternateTriggerInfoCombinedCell';
import AssetCell from '../funds/AssetCell';
import Button from '../shared/Button';
import Divider from '../shared/Divider';
import { InvestModalTabs } from '../modals/InvestModal';
import Skeleton from '../shared/Skeleton';
import TableRow from '../shared/Table/TableRow';
import Text from '@/components/shared/Text';
import TriggerRowCell from '../provide/TriggerRowCell';
import TriggeredHighlightCell from '../funds/ProtectionTriggeredAlert';
import { checkIfTransactionsHavePendingTransaction } from '@/helpers/transactionHelpers';
import { findPositionByMarketId } from '@/helpers/findHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account?: Account;
  allInactiveMarkets?: Market[];
  columnAlignments: ('left' | 'right')[];
  columnWidths: number[];
  index: number;
  isDefault?: boolean;
  layout?: ProtectionRowLayout;
  loading?: boolean;
  market: Market;
  onOpen: (market: Market, tab) => () => void;
  relatedMarkets?: Market[];
  setExpandedDefaultMarketIds?: React.Dispatch<React.SetStateAction<any[]>>;
  transactions: EthereumTransaction[];
}

const InvestRow: React.FC<Props> = ({
  account,
  columnAlignments,
  columnWidths,
  index,
  isDefault,
  layout = ProtectionRowLayout.Default,
  loading,
  market,
  onOpen,
  relatedMarkets,
  setExpandedDefaultMarketIds,
  transactions,
}: Props) => {
  const { account: walletAccount } = useWeb3React();
  const theme = useTheme();

  const { trigger } = market;
  const isTriggered = trigger?.triggered;

  const position = findPositionByMarketId(account, market.id);

  const {
    formattedBorrowRate,
    formattedInvestTokenBalance,
    formattedInvestRate,
    formattedNetRate,
    formattedNetUSDInvestEarnings,
    formattedUSDBorrowBalance,
    formattedUSDInvestBalance,
    netRate,
  } = calculatePositionStats(position, { ...market, ...position?.market });

  const hasPendingTransaction = checkIfTransactionsHavePendingTransaction(
    transactions,
    [EthereumTransactionTypes.Invest, EthereumTransactionTypes.Withdraw],
    market.id,
  );

  const positionHasInvestBalance = checkIfPositionHasBalance(position, PositionTypes.Invest, market);

  const primaryAction = positionHasInvestBalance
    ? onOpen(market, InvestModalTabs.Earnings)
    : onOpen(market, InvestModalTabs.Invest);

  return (
    <Box>
      {index != 0 && <Divider />}
      <TableRow
        backgroundColor={layout === ProtectionRowLayout.Alternate ? 'gray.50' : 'white'}
        isPending={hasPendingTransaction}
        px={6}
        py={4}
        alignItems="center"
        onClick={primaryAction}
      >
        {layout === ProtectionRowLayout.Default && (
          // Asset logo & name
          <AssetCell
            flex={columnWidths[0]}
            market={market}
            position={position}
            showPlatform
            showBalance={false}
            walletAccount={walletAccount}
          />
        )}

        {layout === ProtectionRowLayout.Default && market.triggerAddress && (
          // Additional info for protected markets only
          <TriggerRowCell
            flex={columnWidths[1]}
            market={market}
            positionType={PositionTypes.Invest}
            relatedMarkets={relatedMarkets}
            setExpandedDefaultMarketIds={setExpandedDefaultMarketIds}
            showAvailableToBorrow
            showPlatform={false}
          />
        )}

        {layout === ProtectionRowLayout.Alternate && (
          // Main info for gray sub-row
          <AlternateTriggerInfoCombinedCell
            flex={columnWidths[0] + columnWidths[1]}
            isDefault={isDefault}
            market={market}
          />
        )}

        {isTriggered && (
          // Trigger alert if trigger has been triggered
          <Flex justifyContent="center" alignItems="center" flex={columnWidths[2] + columnWidths[3] + columnWidths[4]}>
            <TriggeredHighlightCell positionType={PositionTypes.Invest} size="sm" trigger={trigger} />
          </Flex>
        )}

        {!isTriggered && (
          <Flex flex={columnWidths[2] + columnWidths[3] + columnWidths[4]} alignItems="center" justifyContent="center">
            {/* Gross */}

            <Box flex={columnWidths[2]} textAlign={columnAlignments[2]} alignItems="center">
              <Skeleton isLoaded={!loading} ml={12}>
                <Text fontSize="md" fontWeight="600" color="gray.500">
                  {formattedInvestRate}
                </Text>
                {positionHasInvestBalance && (
                  <Text fontSize="sm" color="gray.400" mt={1}>
                    {formattedUSDInvestBalance}
                  </Text>
                )}
              </Skeleton>
            </Box>

            <Text position="relative" left="32px" fontSize="md" color="gray.200" textAlign="center">
              {'-'}
            </Text>

            {/* Cost */}
            <Box flex={columnWidths[3]} textAlign={columnAlignments[3]}>
              <Skeleton isLoaded={!loading} ml={12}>
                <Text fontSize="md" fontWeight="600">
                  {formattedBorrowRate}
                </Text>
                {positionHasInvestBalance && (
                  <Text fontSize="sm" color="gray.400" mt={1}>
                    {formattedUSDBorrowBalance}
                  </Text>
                )}
              </Skeleton>
            </Box>

            <Text position="relative" left="32px" fontSize="md" color="gray.200" textAlign="center">
              {'='}
            </Text>

            {/* Net */}
            <Box flex={columnWidths[4]} textAlign={columnAlignments[4]} alignItems="center">
              <Skeleton isLoaded={!loading} ml={12}>
                <Text
                  fontSize="md"
                  fontWeight="600"
                  color={
                    // If zero, use black. If positive use green. If negative, use red.
                    formattedNetRate == '0%' || formattedNetRate == '0.0%'
                      ? null
                      : netRate > 0
                      ? theme.utilityColors.successColor
                      : theme.utilityColors.errorColor
                  }
                >
                  {formattedNetRate}
                </Text>

                {positionHasInvestBalance && (
                  <Text fontSize="sm" color="gray.400" mt={1}>
                    {formattedNetUSDInvestEarnings}
                  </Text>
                )}
              </Skeleton>
            </Box>
          </Flex>
        )}

        <Box flex={columnWidths[5]} textAlign={columnAlignments[5]}>
          <Skeleton isLoaded={!loading} ml={16}>
            <Text fontSize="md" fontWeight="600">
              {formattedUSDInvestBalance}
            </Text>
            {positionHasInvestBalance && (
              <Text fontWeight="400" fontSize="sm" color="gray.500" mt={1}>
                {`${formattedInvestTokenBalance} ${market.underlyingSymbol}`}
              </Text>
            )}
          </Skeleton>
        </Box>

        <Flex flex={columnWidths[6]} justifyContent="flex-end">
          {chooseButton(market, hasPendingTransaction, positionHasInvestBalance, onOpen)}
        </Flex>
      </TableRow>
    </Box>
  );
};

const chooseButton = (
  market: Market,
  hasPendingTransaction: boolean,
  positionHasInvestBalance: boolean,
  onOpen: (market: Market, tab: InvestModalTabs) => () => void,
): ReactNode => {
  if (hasPendingTransaction) {
    return (
      <Button minW="149px" isDisabled>
        Pending
      </Button>
    );
  } else if (positionHasInvestBalance) {
    return (
      <Button minW="149px" onClick={onOpen(market, InvestModalTabs.Earnings)}>
        Manage
      </Button>
    );
  } else {
    return <Button onClick={onOpen(market, InvestModalTabs.Invest)}>Borrow & Invest</Button>;
  }
};

export default InvestRow;
