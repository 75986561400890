import {
  ButtonGroup,
  Modal,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import Heading from '@/components/shared/Heading';
import React from 'react';
import Text from '@/components/shared/Text';
import createPersistedState from 'use-persisted-state';
import { trackEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';

const NUX_BORROWING_POWER_POPOVER = 'NUX_BORROWING_POWER_POPOVER';

const useSeenNuxNotificationsState = createPersistedState('seenNuxNotifications');

interface Props {
  borrowLimitUsdTotal: number;
}

const NuxBorrowingPowerPopover: React.FC<Props> = ({ borrowLimitUsdTotal }: Props) => {
  const router = useRouter();
  const [seenNuxNotifications, setSeenNuxNotifications] = useSeenNuxNotificationsState([]);
  const setSeen = () => setSeenNuxNotifications([...seenNuxNotifications, NUX_BORROWING_POWER_POPOVER]);

  const dismiss = () => {
    trackEvent('NUX Not Now Clicked');
    setSeen();
  };

  const hasBeenSeen = seenNuxNotifications?.includes(NUX_BORROWING_POWER_POPOVER);

  const isOpen = !hasBeenSeen && borrowLimitUsdTotal > 0;

  return (
    <>
      <Modal isOpen={isOpen} onClose={dismiss}>
        <ModalOverlay zIndex="docked" />
      </Modal>

      <Popover isOpen={isOpen} onClose={dismiss} placement="top-start">
        <PopoverContent textAlign="center" p="2.5rem" width="453px" borderRadius="xl">
          <PopoverArrow zIndex="tooltip" />

          <Heading fontSize="2xl" zIndex="tooltip">
            Your Borrowing Power has increased!
          </Heading>
          <Text color="gray.500" mt="1.5rem" px="2rem">
            Do you want to explore Protected Investing now?
          </Text>

          <ButtonGroup mt="2.5rem">
            <Button flex={1} onClick={dismiss}>
              Not Now
            </Button>
            <Button
              appButtonType={ButtonType.Primary}
              onClick={() => {
                trackEvent('NUX Go to Investing Clicked');
                setSeen();
                router.push('/protected-invest');
              }}
            >
              Go to Protected Investing
            </Button>
          </ButtonGroup>
        </PopoverContent>
        <PopoverTrigger>
          <a style={{ position: 'relative', bottom: '55px', left: '30px' }} />
        </PopoverTrigger>
      </Popover>
    </>
  );
};
export default NuxBorrowingPowerPopover;
