import { Account, Market } from '@/types';
import { COMPOUND_ACCOUNT_QUERY, YEARN_ACCOUNT_QUERY } from './fetchQueries';
import { COMPOUND_SUBGAPH_URLS, DEFAULT_CHAIN_ID, PLATFORM_IDS, YEARN_SUBGRAPH_URLS } from '@/constants';
import {
  determineInvestBalanceForInvestmentOpportunity,
  determineInvestLastUpdatedBockNumber,
} from '@/helpers/investHelpers';

import { findInvestInfoForMarket } from '@/helpers/findHelpers';
import { graphQLFetcher } from '@/utils/fetcher';
import useSWR from 'swr';
import { useWeb3React } from '@web3-react/core';

const useFetchInvestAccountData = (
  account: Account,
  enhancedMarkets: Market[],
): { account?: Account; loadingInvestData: boolean } => {
  const { chainId: activeChainId } = useWeb3React();
  const proxyAddress = account?.proxyAddress;

  const chainId = activeChainId ? activeChainId : DEFAULT_CHAIN_ID;

  const shouldQuery = chainId != null && proxyAddress != null;

  const data = {};

  const { data: compoundData, error: compoundError } = useSWR(
    () => (shouldQuery && COMPOUND_SUBGAPH_URLS[chainId] != null ? `compoundData-${chainId}-${proxyAddress}` : null),
    graphQLFetcher(COMPOUND_SUBGAPH_URLS[chainId], COMPOUND_ACCOUNT_QUERY(proxyAddress)),
    { refreshInterval: 3000 },
  );
  data[PLATFORM_IDS.Compound] = compoundData;
  if (compoundError) {
    console.log('Error loading compound account data: ', compoundError);
  }

  const { data: yearnData, error: yearnError } = useSWR(
    () => (shouldQuery && YEARN_SUBGRAPH_URLS[chainId] != null ? `yearnData-${chainId}-${proxyAddress}` : null),
    graphQLFetcher(YEARN_SUBGRAPH_URLS[chainId], YEARN_ACCOUNT_QUERY(proxyAddress)),
    { refreshInterval: 3000 },
  );
  data[PLATFORM_IDS.Yearn] = yearnData;
  if (yearnError) {
    console.log('Error loading yearn account data: ', yearnError);
  }

  const allPlatformsReturned =
    (compoundData != null || COMPOUND_SUBGAPH_URLS[chainId] == null) &&
    (yearnData != null || YEARN_SUBGRAPH_URLS[chainId] == null);
  // (badgerData != null || BADGER_SUBGRAPH_URLS[chainId] == null);

  // const { data: badgerData, error: badgerError } = useSWR(
  //   () => (shouldQuery && BADGER_SUBGRAPH_URLS[chainId] != null ? `badgerData-${chainId}-${proxyAddress}` : null),
  //   graphQLFetcher(BADGER_SUBGRAPH_URLS[chainId], BADGER_ACCOUNT_QUERY(proxyAddress)),
  //   { refreshInterval: 3000 },
  // );
  // data[PLATFORM_IDS.Badger] = badgerData;
  // if (badgerError) {
  //   console.log('Error loading badger account data: ', badgerError);
  // }

  // If not enough data to query, return empty state to user
  if (!shouldQuery) {
    return {
      account: { proxyAddress },
      loadingInvestData: false,
    };
  }

  // If no account data returned, must still be loading & return loading state to user
  if (!allPlatformsReturned) {
    return {
      account: { proxyAddress },
      loadingInvestData: true,
    };
  }

  if (allPlatformsReturned) {
    const positions = account?.positions?.map((position) => {
      const enhancedMarket = enhancedMarkets?.find((market) => market.id == position.market.id);
      const market = { ...position.market, ...enhancedMarket };
      const { investmentOpportunity, investmentPlatform } = findInvestInfoForMarket(market);

      const relevantData = data[investmentOpportunity?.platformId];

      return {
        ...position,
        investBalanceUnderlying: determineInvestBalanceForInvestmentOpportunity(
          investmentOpportunity,
          investmentPlatform,
          relevantData,
          market,
        ),
        investLastUpdatedBlockNumber: determineInvestLastUpdatedBockNumber(
          investmentOpportunity,
          investmentPlatform,
          relevantData,
          market,
        ),
        market,
      };
    });

    return {
      account: {
        ...account,
        positions,
      },

      loadingInvestData: false,
    };
  }
};

export default useFetchInvestAccountData;
