import AnimatedSlothFace, { AnimatedSlothFaceVariants } from '../shared/AnimatedSlothFace';
import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';

import Button from '../shared/Button';
import { ButtonType } from '@/types';
import Heading from '../shared/Heading';
import React from 'react';
import Text from '@/components/shared/Text';
import createPersistedState from 'use-persisted-state';

const NUX_POST_PROTECTED_INVEST_MODAL = 'NUX_POST_PROTECTED_INVEST_MODAL';

const useSeenNuxNotificationsState = createPersistedState('seenNuxNotifications');

interface Props {
  totalInvestBalance: number;
}

const NuxPostProtectedInvestModal: React.FC<Props> = ({ totalInvestBalance }: Props) => {
  const [seenNuxNotifications, setSeenNuxNotifications] = useSeenNuxNotificationsState([]);

  const dismiss = () => {
    setSeenNuxNotifications([...seenNuxNotifications, NUX_POST_PROTECTED_INVEST_MODAL]);
  };

  const hasBeenSeen = seenNuxNotifications?.includes(NUX_POST_PROTECTED_INVEST_MODAL);

  const isOpen = !hasBeenSeen && totalInvestBalance > 0;

  return (
    <Modal isOpen={isOpen} onClose={dismiss}>
      <ModalOverlay />
      <ModalContent borderRadius="lg" minW={{ base: null, md: '520px' }} mx={2}>
        <ModalBody p={10}>
          <Flex justifyContent="center">
            <AnimatedSlothFace size={85} variant={AnimatedSlothFaceVariants.Winking} />
          </Flex>
          <Box textAlign="center">
            <Heading fontSize="2xl" my="1rem">
              Congratulations, you’ve made your first protected investment on Cozy!
            </Heading>
            <Text fontSize="lg" color="gray.500" mb="2rem">
              Check back to this page at anytime to see how your protected investment is performing.
            </Text>
            <Button onClick={dismiss} minWidth="16rem" appButtonType={ButtonType.Primary}>
              Got It
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default NuxPostProtectedInvestModal;
