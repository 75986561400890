import Button from '../shared/Button';
import React from 'react';
import { onHowItWorksModalOpen } from '@/helpers/howItWorksModalHelpers';
import { useRouter } from 'next/router';

const HowItWorksButton: React.FC = () => {
  const router = useRouter();

  return <Button onClick={onHowItWorksModalOpen(router)}>How it works</Button>;
};
export default HowItWorksButton;
