import { Box, Flex, Icon } from '@chakra-ui/react';

import AssetCell from '../funds/AssetCell';
import { BsArrowsCollapse } from 'react-icons/bs';
import Divider from '../shared/Divider';
import Link from '../shared/Link';
import { Market } from '@/types';
import { PLATFORMS } from '@/constants';
import PlatformLogoAndName from '../invest/PlatformLogoAndName';
import React from 'react';
import TableRow from '../shared/Table/TableRow';
import Text from '@/components/shared/Text';
import { findDefaultPlatformByTrigger } from '@/helpers/findHelpers';

interface Props {
  columnWidths: number[];
  expandedDefaultMarketIds: string[];
  market: Market;
  numMarketsInFamily: number;
  setExpandedDefaultMarketIds?: React.Dispatch<React.SetStateAction<any[]>>;
}

const ProtectionFamilyHeader: React.FC<Props> = ({
  columnWidths,
  expandedDefaultMarketIds,
  market,
  numMarketsInFamily,
  setExpandedDefaultMarketIds,
}: Props) => {
  const onClick = () => {
    const withoutThisMarket = expandedDefaultMarketIds.filter((marketId) => marketId != market.id);
    setExpandedDefaultMarketIds(withoutThisMarket);
  };

  const platform = findDefaultPlatformByTrigger(PLATFORMS, market?.trigger);

  return (
    <Box>
      <Divider />
      <TableRow px={6} py={4} alignItems="center" onClick={onClick}>
        <AssetCell flex={columnWidths[0]} market={market} />

        <Box flex={columnWidths[1]}>
          <PlatformLogoAndName platform={platform} />
        </Box>

        <Flex
          alignItems="center"
          justifyContent="flex-end"
          flex={columnWidths[2] + columnWidths[3] + columnWidths[4] + columnWidths[5] + columnWidths[6]}
        >
          <Text fontSize="sm" color="gray.500">
            Showing {numMarketsInFamily} available protection{numMarketsInFamily === 1 ? '' : 's'}
          </Text>
          <Link mt="1px" ml={3} mr={3} fontSize="sm" fontWeight="600">
            Close
          </Link>
          <Icon mt="1px" as={BsArrowsCollapse} boxSize="16px" color="gray.500" />
        </Flex>
      </TableRow>
    </Box>
  );
};
export default ProtectionFamilyHeader;
