import { Image } from '@/components/shared/Image';
import React from 'react';

export enum AnimatedSlothFaceVariants {
  Frowning = 'Frowning',
  Hushed = 'Hushed',
  Winking = 'Winking',
}

interface Props {
  variant: AnimatedSlothFaceVariants;
  size: number;
}

const AnimatedSlothFace: React.FC<Props> = ({ variant, size }: Props) => {
  const src = `/images/logos/animated/${variant}_Face_Color.gif`;

  return (
    <Image
      alt="Cozy sloth-face logo with Cozy wordmark"
      htmlHeight={size}
      htmlWidth={size}
      layout="fixed"
      loading="eager"
      src={src}
    />
  );
};
export default AnimatedSlothFace;
