import { Box, Collapse, Flex, Stack, useTheme } from '@chakra-ui/react';
import { EthereumTransactionTypes, MarketTypes, PositionTypes, ProtectionRowLayout } from '@/types';
import React, { useContext, useEffect, useState } from 'react';
import { SortFields, sortMarkets } from '@/helpers/sortHelpers';
import {
  checkIfTransactionsHavePendingTransaction,
  conditionallyToastVaultJustSynced,
} from '@/helpers/transactionHelpers';
import useFetchMarketData, { SubgraphData } from '@/hooks/useFetchMarketData';

import BorrowLimitPanel from '@/components/shared/BorrowingPowerPanel';
import CommonModals from '@/components/modals/CommonModals';
import Head from 'next/head';
import HowItWorksButton from '@/components/buttons/HowItWorksButton';
import InvestRow from '@/components/invest/InvestRow';
import { MIN_POSITION_BALANCE } from '@/helpers/positionHelpers';
import NoMarketsFound from '@/components/funds/NoMarketsFound';
import NuxPostProtectedInvestModal from '@/components/modals/NuxPostProtectedInvestModal';
import PageHeader from '@/components/shared/PageHeader';
import ProtectionFamilyFooter from '@/components/protection/ProtectionFamilyFooter';
import ProtectionFamilyHeader from '@/components/protection/ProtectionFamilyHeader';
import Stat from '@/components/shared/Stat';
import StatContainer from '@/components/shared/StatContainer';
import Table from '@/components/shared/Table/Table';
import TableEmptySpace from '@/components/shared/Table/TableEmptySpace';
import TheGraphLoadingTag from '@/components/shared/TheGraphLoadingTag';
import { TransactionContext } from '@/contexts/TransactionContext';
import { VerifiedAddress } from '@/helpers/preloadDataHelpers';
import { calculateAccountStats } from '@/helpers/accountHelpers';
import { filterByInvestMarkets } from '@/helpers/investHelpers';
import { formatNumber } from '@/helpers/formatHelpers';
import { onManageModalOpen } from '@/helpers/manageModalHelpers';
import useFetchAccountData from '@/hooks/useFetchAccountData';
import useFetchInvestAccountData from '@/hooks/useFetchInvestAccountData';
import useFetchInvestMarkets from '@/hooks/useFetchInvestMarkets';
import { useRouter } from 'next/router';
import { useSortRouterParams } from '@/hooks/useSortRouterParams';
import { useWeb3React } from '@web3-react/core';

interface Props {
  initialSubgraphData: SubgraphData;
  verifiedAddresses: Record<string, VerifiedAddress>;
}

export const protectionSubnav = [
  { href: '/protected-invest', label: 'Protected Investing' },
  { href: '/protected-borrow', label: 'Protected Borrowing' },
  { href: '/provide', label: 'Provide Protection' },
];

const ProtectedInvest: React.FC<Props> = ({ initialSubgraphData, verifiedAddresses }: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const { sortBy, sortOrder } = useSortRouterParams();

  const { comptroller, markets } = useFetchMarketData(initialSubgraphData, verifiedAddresses);
  const { account: cozyAccount, loadingAccountData, errorLoadingAccountData } = useFetchAccountData(verifiedAddresses);

  const { enhancedMarkets, loadingInvestMarkets } = useFetchInvestMarkets(markets);

  const { account: enhancedAccount, loadingInvestData } = useFetchInvestAccountData(cozyAccount, enhancedMarkets);

  const { account: walletAccount } = useWeb3React();

  const [expandedDefaultMarketIds, setExpandedDefaultMarketIds] = useState([]);

  const { transactions, setTransactions, toast } = useContext(TransactionContext);

  const hasPendingTransaction =
    enhancedMarkets.find((market) =>
      checkIfTransactionsHavePendingTransaction(
        transactions,
        [EthereumTransactionTypes.Invest, EthereumTransactionTypes.Withdraw],
        market.id,
      ),
    ) != null;

  const rowsAreLoading = loadingAccountData || loadingInvestData || loadingInvestMarkets;
  const dashboardIsLoading = rowsAreLoading || hasPendingTransaction;

  useEffect(() => {
    conditionallyToastVaultJustSynced(transactions, enhancedAccount?.positions, setTransactions, toast);
  });

  const investMarkets = filterByInvestMarkets(enhancedMarkets);

  const {
    totalBorrowBalance,
    totalInvestBalance,
    totalInvestInterestAccrued,
    netInvestInterestRate,
  } = calculateAccountStats(enhancedAccount, MarketTypes.ProtectionMarket);

  const { activeMarkets, hasMarkets, defaultInactiveMarkets, platformUnderlyingPairMarkets } = sortMarkets(
    investMarkets,
    enhancedAccount,
    ['borrowBalanceUnderlying', 'investBalanceUnderlying'],
    {
      field: sortBy,
      order: sortOrder,
    },
  );

  const columnAlignments: ('left' | 'right')[] = ['left', 'left', 'right', 'right', 'right', 'right', 'right'];
  const columnWidths = [3, 2, 1.5, 1.5, 1.5, 2, 2.5];
  const columnLabels = [
    { label: 'Asset', sortField: SortFields.Name },
    { label: 'Protected Against', sortField: SortFields.ProtectedAgainst },
    { label: 'Gross', sortField: SortFields.SupplyRate },
    { label: 'Cost', sortField: SortFields.BorrowRate },
    { label: 'Net Interest', sortField: SortFields.NetRate },
    { label: 'Your Balance', sortField: SortFields.InvestBalance },
    { label: '', sortField: null },
  ];

  return (
    <>
      <Head>
        <title>Protected Investing</title>
      </Head>

      <CommonModals
        account={enhancedAccount}
        comptroller={comptroller}
        markets={enhancedMarkets}
        positionType={PositionTypes.Invest}
        protectedScreen
      />

      <TheGraphLoadingTag errorLoading={errorLoadingAccountData} isLoading={loadingAccountData} />

      <NuxPostProtectedInvestModal totalInvestBalance={totalInvestBalance} />

      <Flex flexDir="column" alignItems="center" mb={16}>
        <PageHeader
          button={<HowItWorksButton />}
          description="Invest borrowed assets from Cozy instantly in DeFi investment opportunities. If the trigger event happens, you don’t have to pay your loan back."
          subnav={protectionSubnav}
          title="Protected Investing"
        />

        <Box maxW={theme.maxPageWidth} width="100%" px={{ base: 4, md: 6, xl: 8 }}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={6}>
            <StatContainer flex={3}>
              <Flex>
                <Stat
                  flex={1}
                  label="Invested Balance"
                  loading={dashboardIsLoading}
                  number={totalInvestBalance < MIN_POSITION_BALANCE ? 0 : formatNumber(totalInvestBalance, '0,0.00')}
                  unit="$"
                />
                <Stat
                  flex={1}
                  label="Net Interest Earned"
                  loading={dashboardIsLoading}
                  number={
                    totalInvestInterestAccrued < MIN_POSITION_BALANCE ? 0 : formatNumber(totalInvestInterestAccrued)
                  }
                  unit="$"
                  color="gray.500"
                />
              </Flex>
            </StatContainer>

            <StatContainer flex={1} ml={6}>
              <Stat
                label="Current Net Earning (APY)*"
                loading={dashboardIsLoading}
                number={
                  totalInvestBalance == 0 && totalBorrowBalance == 0
                    ? 0
                    : formatNumber(netInvestInterestRate * 100, '0.0')
                }
                unit="%"
                color={netInvestInterestRate > 0 ? theme.utilityColors.successColor : theme.utilityColors.errorColor}
              />
            </StatContainer>
          </Stack>

          <Table
            columnAlignments={columnAlignments}
            columnLabels={columnLabels}
            columnWidths={columnWidths}
            minW="66em"
          >
            {!hasMarkets && <NoMarketsFound />}

            {/* Active Rows */}
            {activeMarkets.map((market, index) => (
              <InvestRow
                account={enhancedAccount}
                columnAlignments={columnAlignments}
                columnWidths={columnWidths}
                index={index}
                key={market.id}
                loading={loadingAccountData}
                market={market}
                onOpen={onManageModalOpen(router)}
                transactions={transactions}
              />
            ))}

            {activeMarkets.length > 0 && defaultInactiveMarkets.length > 0 && <TableEmptySpace />}

            {/* Inactive Rows */}
            {defaultInactiveMarkets.map((market, index) => {
              const { allMarkets, defaultMarket } = Object.values(platformUnderlyingPairMarkets).find(
                ({ defaultMarket }) => defaultMarket.id === market.id,
              );

              const isExpanded = expandedDefaultMarketIds.includes(market.id);

              return (
                <Box key={market.id} boxShadow={isExpanded ? 'highlight' : 'none'}>
                  {expandedDefaultMarketIds.includes(market.id) ? (
                    <ProtectionFamilyHeader
                      columnWidths={columnWidths}
                      expandedDefaultMarketIds={expandedDefaultMarketIds}
                      market={market}
                      numMarketsInFamily={allMarkets.length}
                      setExpandedDefaultMarketIds={setExpandedDefaultMarketIds}
                    />
                  ) : (
                    <InvestRow
                      account={enhancedAccount}
                      relatedMarkets={allMarkets}
                      columnAlignments={columnAlignments}
                      columnWidths={columnWidths}
                      index={index}
                      loading={rowsAreLoading}
                      market={market}
                      onOpen={onManageModalOpen(router)}
                      setExpandedDefaultMarketIds={setExpandedDefaultMarketIds}
                      transactions={transactions}
                    />
                  )}
                  {allMarkets.length > 1 && (
                    <Collapse in={isExpanded} animateOpacity>
                      {allMarkets.map((market) => (
                        <InvestRow
                          account={enhancedAccount}
                          columnAlignments={columnAlignments}
                          columnWidths={columnWidths}
                          isDefault={defaultMarket.id === market.id}
                          layout={ProtectionRowLayout.Alternate}
                          loading={rowsAreLoading}
                          index={index}
                          key={market.id}
                          market={market}
                          onOpen={onManageModalOpen(router)}
                          transactions={transactions}
                        />
                      ))}
                      <ProtectionFamilyFooter
                        expandedDefaultMarketIds={expandedDefaultMarketIds}
                        market={market}
                        setExpandedDefaultMarketIds={setExpandedDefaultMarketIds}
                      />
                    </Collapse>
                  )}
                </Box>
              );
            })}
          </Table>
        </Box>
      </Flex>

      {walletAccount && <BorrowLimitPanel account={enhancedAccount} />}
    </>
  );
};

// export const getStaticProps: GetStaticProps = async () => {
//   return preloadSubgraphData();
// };

export default ProtectedInvest;
