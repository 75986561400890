import { Box, Flex, Stack } from '@chakra-ui/react';

import { Market } from '@/types';
import React from 'react';
import Tag from '../shared/Tag';
import Text from '@/components/shared/Text';

interface Props {
  flex: number;
  isDefault: boolean;
  market: Market;
}

const AlternateTriggerInfoCombinedCell: React.FC<Props> = ({ flex, isDefault, market }: Props) => {
  const { trigger } = market;
  const { creator } = trigger;

  return (
    <Stack flex={flex} spacing={1}>
      {isDefault && (
        <Box>
          <Tag ml="-1px">Default</Tag>
        </Box>
      )}
      <Flex fontSize="sm">
        <Text fontWeight="600">{trigger.name}</Text>
        {creator && (
          <Text color="gray.500" ml="2px" fontStyle="italic">
            by {creator.name}
          </Text>
        )}
      </Flex>
      <Text fontSize="sm">{trigger.description}</Text>
    </Stack>
  );
};
export default AlternateTriggerInfoCombinedCell;
